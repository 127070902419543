<template>
	<div class="box"  v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
		<div class="success" v-if="flage">
			<img src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/Paysuccess.png" />
			<p>支付成功!</p>
		</div>
		<div>
			<div class="success" v-if="!flage">
				<img src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/yc.png" />
				<P>支付订单存在异常,请联系:4006182019</P>
			</div>
		</div>
		<div class="btn">
			<button @click="$router.push('/')">返回首页</button>
			<button @click="selfun" v-if="!flage">已付款？点击查询订单！</button>
		</div>
	</div>
</template>

<script>
	const axi = require('../../assets/axi.js')
	export default {
		data() {
			return {
				flage: false,
				code:"",
				loading: true
			}
		},
		mounted() {
			// alert(this.$store.state.prepayid)
			// this.$store.state.prepayid
			let datas = window.location.href
			// let datas='http://www.rhdgj.com/#/Alipay?charset=UTF-8&out_trade_no=161090003720211028170956288&method=alipay.trade.page.pay.return&total_amount=0.01&sign=XpW45ytEo2X%2BqKFXUrIxSaCAf1PNRZvgujiWcH0huGZbN34KhZB1dnv7Lxpq6cu%2FIQ1ig6EQRBL5hyYLLUdvvhOE9lLz3vYgRsgFG1v%2FGstW7GUJNyVxaqvqOGKFTgH2OWpG7Kg6P2P7fVCNdtJxb0y9WJWxuwcwSMViUFbiWSejO3AyEN%2FhIlmiS8NOQIAPpZEksj2VRTvgPIjzojPfDXMNCwHQHWeaBXIhBN%2FLnzYDGlD1SGWpmKw1uuS22kR51Kf05DeZeyr4TcUToTyJPIerClrOvumxZQk%2Btf5eyHH6eGBUvIBxlMryEZRxLbfVBSlh%2BnlG1Z2JYcetf3tfKw%3D%3D&trade_no=2021102822001411960501632300&auth_app_id=2021000118639692&version=1.0&app_id=2021000118639692&sign_type=RSA2&seller_id=2088621956759262&timestamp=2021-10-28%2017%3A10%3A39'
			console.log(datas)
			if(datas.split('#')[1].split("&")[1]){
				var code=datas.split('#')[1].split("&")[1].split('=')[1]
				console.log(code)
				this.code=code
				this.selfun()
			}else{
				this.loading=false
			}
			
		},
		methods: {
			selfun(){
				// console.log(123)
				axi.post('Pay/Is_AlipayOrder?Out_trade_no='+this.code).then((res)=>{
					console.log(res)
					if(res.data){
						this.flage=true
						this.loading=false
					}else{
						this.$message.error(res.data.Msg)
						this.loading=false
					}

				})
			}
		}
	}
</script>

<style scoped="scoped" lang="less">
	@media screen and(max-width:720px) {
		.success {
			width: 100vw;
			height: 60vh;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			align-content: center;

			p {
				width: 100%;
				text-align: center;
				margin-top: 30px;
				font-weight: bold;
			}
		}

		.btn {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;

			button {
				width: 60%;
				height: 40px;
				background-color: #5AB92B;
				color: #FFFFFF;
				border-radius: 40px;
				margin-bottom: 30px;
			}
		}
	}

	@media screen and(min-width:720px) {
		.success {
			width: 100vw;
			height: 60vh;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			align-content: center;

			p {
				width: 100%;
				text-align: center;
				margin-top: 30px;
				font-weight: bold;
			}
		}

		.btn {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			flex-direction: column;
			align-content: center;

			button {
				width: 200px;
				height: 60px;
				background-color: #5AB92B;
				color: #FFFFFF;
				border-radius: 40px;
				margin-bottom: 30px;
				display: block;
			}
		}
	}
</style>
